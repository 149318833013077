import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

const MainMenu = () => {
  const { allWpMenu } = useStaticQuery(
    graphql`
      query MainMenuQuery {
        allWpMenu(filter: { name: { eq: "Main menu" } }) {
          nodes {
            name
            menuItems {
              nodes {
                label
                url
                cssClasses
              }
            }
          }
        }
      }
    `
  )

  let output = []

  const menuItems = allWpMenu.nodes[0]?.menuItems?.nodes

  if (menuItems && menuItems.length) {
    let i = 1
    for (const link of menuItems) {
      let className = link.cssClasses.join(" ")

      output.push(
        <>
          <Link key={'l-' + link.url} to={link.url}>
            <span key={'is-' + link.url} className="icon">
              <i key={'i-' + link.url} className={className}></i>
            </span>
            {link.label}
          </Link>
          <span key={'s-' + link.url} className="menu-item-separator">
            {i < menuItems.length ? "|" : ""}
          </span>
        </>
      )
      i++
    }
  }

  return <div className="main-menu">{output}</div>
}

export default MainMenu
