import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import parse from "html-react-parser"
import { Columns } from "react-bulma-components"
import MainMenu from "./main-menu"
import config from "../../config"
import { Helmet } from "react-helmet"

interface Props {
  locationHref: string
  isHomePage: boolean
  children?: any
}

const Layout = ({ locationHref, isHomePage, children }: Props) => {
  const {
    wp: {
      generalSettings: { title },
    },
  } = useStaticQuery(graphql`
    query LayoutQuery {
      wp {
        generalSettings {
          title
          description
        }
      }
    }
  `)

  return (
    <>
      <Helmet>
        <link rel="webmention" href={`https://webmention.io/${config.SITE_DOMAIN}/webmention`} />
        <link rel="pingback" href={`https://webmention.io/${config.SITE_DOMAIN}/xmlrpc`} />     
        <script src="/webmention.js" async></script>   
      </Helmet>
      <div className="global-wrapper" data-is-root-path={isHomePage}>
        <MainMenu />
        <header className="global-header">
          {isHomePage ? (
            <div className="main-heading">
              <Link to="/">{parse(title, { trim: true })}</Link>
            </div>
          ) : (
            <Link className="header-link-home" to="/">
              {parse(title, { trim: true })}
            </Link>
          )}
        </header>
        <Columns>
          <Columns.Column size={10}>
            <main className="layout-content">{children}</main>
          </Columns.Column>
          <Columns.Column size={10}>
            <Columns>
              <Columns.Column size={10}>
                <div className="site-index-link">
                  <span className="icon">
                    <i className="fas fa-list"></i>
                  </span>
                  <div>
                    <Link to="/site-index">View the site index</Link>
                  </div>
                  {config.PGP_KEY_NAME && (
                    <div>
                      <Link to={`/${config.PGP_KEY_NAME}`} rel="pgpkey authn">
                        PGP Key
                      </Link>
                    </div>
                  )}
                </div>
              </Columns.Column>
              <Columns.Column></Columns.Column>
            </Columns>
          </Columns.Column>
        </Columns>
        {/* <footer>
        <div>
          <div>- .... . -.-. .- -.- . .. ... .- .-.. .. .</div>
          <a href="https://everything-is-balance-in-this.life">
            Balance Is Everything
          </a>
          &nbsp;|&nbsp;
          <a href="https://political-incorruption.com">
            Political Incorruption
          </a>
          &nbsp;|&nbsp;
          <a href="https://my-thoughts-are-not-my-own.com">
            My Thoughts Are Not My Own
          </a>          
        </div>
      </footer> */}
      </div>
    </>
  )
}

export default Layout
